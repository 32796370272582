<template>
    <section aria-labelledby="payment-and-shipping-heading" class="py-8 md:py-16 lg:w-full lg:mx-auto lg:pt-0 lg:pb-24 lg:row-start-1 lg:col-start-1 lg:col-end-7">
        <div class="max-w-2xl mx-auto px-4 lg:max-w-none lg:px-0">
            <div>
                <div class="flex items-center">
                    <div class="h-14 w-14 text-gray-700 mr-0 md:mr-4">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div>
                        <span class="text-sm text-gray-600 infline-flex">Ordine {{user.numero_ordine}}</span>
                        <h3 id="contact-info-heading" class="text-2xl text-gray-900">Grazie {{user.nome}} {{user.cognome}}</h3>
                    </div>
                </div>
              <div class="mt-8 border border-gray-300 rounded px-4 py-4">
                  <span>Il tuo ordine è confermato</span>
                  <p class="mt-2 text-gray-700 text-sm">Riceverai in 24/48 ore una email con tutte le istruzioni e le credenziali per accedere.</p>
              </div>

              <div class="mt-4 border border-gray-300 rounded px-4 py-4">
                  <span>Informazioni cliente</span>
                  <div class="grid grid-cols-1 md:grid-cols-2 mt-4">
                      <div>
                          <span class="text-sm inline-flex">Recapiti</span>
                          <p class="text-sm text-gray-600 mt-2">{{user.email}}</p>
                      </div>
                      <div v-if="user.fattura == 'yes' ">
                          <span class="text-sm inline-flex mt-8 md:mt-0">Dati di fatturazione</span>
                          <p class="text-sm text-gray-600 mt-2">{{user.nome}} {{user.cognome}}</p>
                          <p class="text-sm text-gray-600 mt-2" v-if="user.ragionesociale">{{user.ragionesociale}}</p>
                          <p class="text-sm text-gray-600 mt-2">{{user.piva}}</p>
                          <p class="text-sm text-gray-600 mt-2">{{user.indirizzo}}</p>
                          <p class="text-sm text-gray-600 mt-2">{{user.cap}} {{user.citta}} {{user.provincia}}</p>
                          <p v-if="user.telefono" class="text-sm text-gray-600 mt-2">{{user.telefono}}</p>
                          <p v-if="user.pec" class="text-sm text-gray-600 mt-2">{{user.pec}}</p>
                          <p v-if="user.codicefiscale" class="uppercase text-sm text-gray-600 mt-2">{{user.codicefiscale}}</p>
                      </div>
                  </div>
              </div>

            </div>          
        </div>

        <div class="max-w-2xl mx-auto px-4 lg:max-w-none lg:px-0">
            <div class="mt-4 mt-10 pb-12">
                <a href="https://sanifad.com/" class="button--big bg-green-600 bg-opacity-80 border border-transparent rounded-md text-sm font-medium text-white hover:bg-green-500 hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-900">Torna al negozio</a>            
                <a href="mailto:info@sanifad.com" class="block mt-10 md:mt-0 md:inline md:text-center text-sm text-gray-900 pl-0 md:pl-6 mt-4 md:mt-0">Hai bisogno di aiuto? Contattaci</a>            
            </div>
            <BottomMenu />        
        </div>

    </section>
</template>

<script>
import BottomMenu from './BottomMenu.vue';
import axios from 'axios';
import { state } from '../components/Product';
import {computed} from 'vue';

let user = {};
const thankyou = computed({
  get: () => state.thankyou,  
});

export default {
    data() {
        return {
            user,
            thankyou
        }
    },
    async mounted() {            
        let _user = await axios.get(`https://sanistrapi.caprover.shopifive.com/attivazionis/${this.$route.params.id}`);
        this.user = _user.data;
        // let id = this.$route.params.order;
        // !id ? this.$router.push('step1') : null;
    },
    components: {
        BottomMenu
    }
}

</script>